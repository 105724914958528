import { Sidebar, Menu, MenuItem  } from "react-pro-sidebar";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";

import { setTitle } from "../../store/titleSlice";
import { setImageFile } from '../../store/titleImageFileSlice.js';
import { setEditorState } from '../../store/EditorStateSlice.js';
import { setHTML } from '../../store/htmlFromCurrentEditorStateSlice.js';
import { setSelectedQueryElements } from '../../store/selectedQueryElementsSlice.js';

import EditNoteIcon from '@mui/icons-material/EditNote';
import AddIcon from '@mui/icons-material/Add';

export default function SideBar() {
    const dispatch = useDispatch();


    function clearReduxStates(){
        dispatch(setTitle(""));
        dispatch(setImageFile(null));
        dispatch(setEditorState(null));
        dispatch(setHTML(null));
        dispatch(setSelectedQueryElements({}));
        window.scrollTo({top: 0});
    }

    return (
        <div style={{ display: 'flex', height: '100%', gridColumnStart: "1", gridRowStart: "2"}}>
            <Sidebar width="300px">
                <Menu>
                    <MenuItem component={<Link to="/create-new-article" />} onClick={clearReduxStates} icon={<AddIcon></AddIcon>}> Новая статья </MenuItem>
                    <MenuItem component={<Link to="/edit-article" />} onClick={clearReduxStates} icon={<EditNoteIcon></EditNoteIcon>}> Редактировать статью </MenuItem>
                </Menu>
            </Sidebar>  
        </div>
    );
}