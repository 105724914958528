import { useMatch } from "react-router-dom";
import CreateArticlePage from "../CreateArticlePage/CreateArticlePage";
import Loader from "../Loader/Loader";
import { useGetValuesForEditorPageQuery, useGetImageForArticleQuery } from "../../store/apiSlice";


export default function InterlayerForEditPage(){
    const match = useMatch('/edit-article/:ID')
    const id = match?.params.ID;

    const {
        data,
        isLoading,
        isSuccess
    } = useGetValuesForEditorPageQuery(id);

    const {
        data: image,
        isLoading: isLoadingImage,
        isSuccess: isSuccessImage
    } = useGetImageForArticleQuery({id, name: "title"});

    let content;
    if(isLoading || isLoadingImage){
        content = <Loader />
    }
    else if(isSuccess && isSuccessImage){
        content = <CreateArticlePage data={data} image={image}/>
        
    }

    return (<>{content}</>);
}