import React, { useRef, useEffect } from 'react';
import { useLocation, useMatch } from 'react-router-dom';

import { useGetQueryDataQuery } from '../../store/apiSlice';
import { useDispatch } from "react-redux";
import { setTitle } from "../../store/titleSlice";
import { setImageFile } from '../../store/titleImageFileSlice.js';
import { setEditorState } from '../../store/EditorStateSlice.js';
import { setHTML } from '../../store/htmlFromCurrentEditorStateSlice.js';
import { setSelectedQueryElements } from '../../store/selectedQueryElementsSlice.js';

import TitleTextField from '../TitleTextField/TitleTextField.js';
import Editor from "../Editor/Editor.js";
import ImageLoader from '../ImageLoader/ImageLoader.js';
import SetterTags from '../SetterTags/SetterTags.js';
import SubmitButton from '../SubmitButton/SubmitButton.js';
import Loader from '../Loader/Loader.js';

import styles from './CreateArticlePage.module.css';

export default function CreateArticlePage({data, image}) {
    //const imageRef = useRef(null);
    const {
      data: query_data,
      isSuccess,
      isLoading
    } = useGetQueryDataQuery();
    const dispatch = useDispatch();

    useEffect(() => {
      if(data !== null){
        dispatch(setTitle(data.title));
        
        const strImage = image.replace(/^data:image\/[a-z*]+;base64,/, "");
        //console.log(strImage);
        dispatch(setImageFile(strImage));
        dispatch(setEditorState(data.editorState));
        dispatch(setHTML(data.html));
        dispatch(setSelectedQueryElements(data.tags));
      }
    }, []);
    
    

    // const reader = new FileReader();
    // reader.addEventListener(
    //     "load",
    //     () => {
    //       imageRef.current.src = reader.result;
    //     },
    //     false,
    // );
    
    
    let contentSetterTags = <></>;

    if(isSuccess){
      contentSetterTags = <SetterTags query_data={query_data}/>;
    }
    else if(isLoading){
      contentSetterTags = <Loader />;
    }

    return (
        <div className={styles.wrapper}>
            <TitleTextField title={data?.title}/>
            <Editor editorState={data?.editorState}/>
            <ImageLoader imageFile={image}/>
            {contentSetterTags}
            <SubmitButton />
        </div>
    );
}