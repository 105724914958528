import { useDispatch } from 'react-redux';

import styles from './RequestElement.module.css';
import { addSlectedQueryElement } from '../../store/selectedQueryElementsSlice';
import { deleteSelectedQueryElement } from '../../store/selectedQueryElementsSlice';

export default function RequestElement({children, selected, hintTextEn}){ 
    const dispatch = useDispatch();

    function handleClickOnReqEl (e) {
        e.stopPropagation();
        if(selected){
            return;
        }
        dispatch(addSlectedQueryElement(children, hintTextEn));
    }

    function handleClickOnCloseButton (e) {
        e.stopPropagation();
        dispatch(deleteSelectedQueryElement(children, hintTextEn));
    }

    return (   
        <div className={`${styles.req_el} ${selected && styles.selected}`} onClick={handleClickOnReqEl}> 
            <div className={styles.text}>{children}</div>
            {selected && 
            <div className={styles.wrap_close_button} onClick={handleClickOnCloseButton}>
               <img className={styles.close_button} src={process.env.PUBLIC_URL + "/images/icons/close_button.svg"} alt='Кнопка удаления плитки'/>
            </div>}
        </div>
    );
}