import { createSlice } from "@reduxjs/toolkit";

const selectedQueryElementsSlice = createSlice({
    name: "selectedQueryElements",
    initialState: {},
    reducers: {
        addSlectedQueryElement: {
            reducer (state, action) {
                const text = action.payload.text;
                const hintTextEn = action.payload.hintTextEn;
                
                if(!state.hasOwnProperty(hintTextEn)) {
                    state[hintTextEn] = [];
                }
                state[hintTextEn].push(text);
            },
            prepare (text, hintTextEn) {
                return {
                    payload: {
                        text,
                        hintTextEn,
                    }
                }
            },
        },
        deleteSelectedQueryElement: {
            reducer (state, action) {
                const text = action.payload.text;
                const hintTextEn = action.payload.hintTextEn;

                state[hintTextEn] = state[hintTextEn].filter(el => el !== text);
            },
            prepare (text, hintTextEn) {
                return {
                    payload: {
                        text,
                        hintTextEn,
                    }
                }
            }
        },
        setSelectedQueryElements(state, action) {
            return action.payload;
        }
    }
})

export const {addSlectedQueryElement, deleteSelectedQueryElement, setSelectedQueryElements} = selectedQueryElementsSlice.actions;

export default selectedQueryElementsSlice.reducer;

export const selectObjectWithTags = (state) => state.selectedQueryElements;