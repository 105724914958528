import {createSlice} from "@reduxjs/toolkit";

const editorStateSlice = createSlice({
    name: "htmlFromCurrentEditorState",
    initialState: null,
    reducers: {
        setEditorState(state, action) {
            return action.payload;
        }
    }
})

export const {setEditorState} = editorStateSlice.actions;

export default editorStateSlice.reducer;